<template>
  <div></div>
</template>
<script>
export default {
  created () {
    this.$store.dispatch('auth/logout')
      .then(() => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userInfo')

        //REVER ACLS

        // Change role on logout. Same value as initialRole of acj.js
        this.$acl.change('admin')

        // Recarrega a página atual sem usar o cache
        document.location.reload() // ESTOU USANDO ISSO PARA ESVAZIAR O STATE DO VUEX FORÇANDO UM RELOAD

        // this.$router.push('/login').catch(() => {})
      })
      .catch(() => {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userInfo')
        // Recarrega a página atual sem usar o cache
        document.location.reload() // ESTOU USANDO ISSO PARA ESVAZIAR O STATE DO VUEX FORÇANDO UM RELOAD
        // this.$router.push('/login').catch(() => {})
      })
  }
}
</script>
